import React, { useEffect, useRef, useState, useContext } from 'react';
import { FaDatabase, FaLink, FaExchangeAlt, FaChartLine, FaIndustry, FaShippingFast, FaWarehouse, FaChartBar } from 'react-icons/fa';
import Globe from 'react-globe.gl';
import { motion, useAnimation, AnimatePresence } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import emailjs from 'emailjs-com';
import { ThemeContext } from './ThemeContext';

const TextCycler = ({ texts, interval }) => {
  const [displayText, setDisplayText] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isTyping, setIsTyping] = useState(true);
  const [typingSpeed, setTypingSpeed] = useState(100);
  const [isFinished, setIsFinished] = useState(false);
  const [showCursor, setShowCursor] = useState(true);

  useEffect(() => {
    let timer;
    let cursorTimer;

    const animateText = () => {
      if (isFinished) {
        return;
      }

      const currentText = texts[currentIndex];

      if (isTyping && displayText === currentText) {
        if (currentIndex === texts.length - 1) {
          setIsFinished(true);
          return;
        }
        setIsTyping(false);
        setTypingSpeed(30);
        timer = setTimeout(animateText, 750); // Pause before erasing
        return;
      }

      if (!isTyping && displayText === '') {
        setIsTyping(true);
        setCurrentIndex((prevIndex) => prevIndex + 1);
        setTypingSpeed(100);
        timer = setTimeout(animateText, typingSpeed);
        return;
      }

      if (isTyping) {
        const newText = currentText.substring(0, displayText.length + 1);
        setDisplayText(newText);
      } else {
        const newText = displayText.substring(0, displayText.length - 1);
        setDisplayText(newText);
      }

      timer = setTimeout(animateText, typingSpeed);
    };

    const blinkCursor = () => {
      setShowCursor((prev) => !prev);
    };

    timer = setTimeout(animateText, typingSpeed);
    cursorTimer = setInterval(blinkCursor, 500); // Blink cursor every 500ms

    return () => {
      clearTimeout(timer);
      clearInterval(cursorTimer);
    };
  }, [texts, interval, currentIndex, displayText, isTyping, typingSpeed, isFinished]);

  return (
    <span>
      {displayText}
      <span style={{ visibility: showCursor ? 'visible' : 'hidden' }}>_</span>
    </span>
  );
};

const FadeInSection = ({ children }) => {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    } else {
      controls.start('hidden');
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial="hidden"
      variants={{
        visible: { opacity: 1, y: 0 },
        hidden: { opacity: 0, y: 50 }
      }}
      transition={{ duration: 0.5 }}
      viewport={{ once: true }}
    >
      {children}
    </motion.div>
  );
};

const LandingPage = () => {
  const { theme } = useContext(ThemeContext);
  const globeRef = useRef();
  const [globeData, setGlobeData] = useState({ arcs: [], labels: [] });
  const [submitStatus, setSubmitStatus] = useState(null);

  useEffect(() => {
    if (globeRef.current) {
      const controls = globeRef.current.controls();
      controls.enableZoom = false;
      controls.enablePan = false;
      controls.autoRotate = true;
      controls.autoRotateSpeed = 0.6;

      globeRef.current.pointOfView(
        {
          lat: 25.0,    // Adjusted latitude for a higher view
          lng: 10.0,    // Longitude centered on Europe
          altitude: 2.6 // Adjust altitude as needed
        },
        0 // Transition duration in milliseconds (0 for immediate)
      );

      // Updated Vessel Locations (near major shipping lanes)
      const vesselLocations = [
        // North America
        { lat: 32.0, lng: -130.0, continent: 'North America' }, // Pacific Ocean west of California
        { lat: 38.0, lng: -60.0, continent: 'North America' },  // Atlantic Ocean east of the U.S.

        // Europe
        { lat: 57.0, lng: -15.0, continent: 'Europe' },         // North Atlantic Ocean northwest of Ireland
        { lat: 61.0, lng: 0.0, continent: 'Europe' },           // North Sea north of the UK

        // Asia
        { lat: 28.0, lng: 135.0, continent: 'Asia' },           // Pacific Ocean southeast of Japan
        { lat: -8.0, lng: 115.0, continent: 'Asia' },           // Indian Ocean south of Indonesia

        // Africa
        { lat: -20.0, lng: 15.0, continent: 'Africa' },         // South Atlantic Ocean west of Namibia
        { lat: 10.0, lng: 25.0, continent: 'Africa' },          // Gulf of Guinea southwest of Nigeria

        // South America
        { lat: -40.0, lng: -50.0, continent: 'South America' }, // South Atlantic Ocean east of Argentina
        { lat: -15.0, lng: -30.0, continent: 'South America' }, // Atlantic Ocean northeast of Brazil

        // Australia
        { lat: -42.0, lng: 155.0, continent: 'Australia' },     // Tasman Sea southeast of Australia
        { lat: -25.0, lng: 165.0, continent: 'Australia' },     // Coral Sea northeast of Australia
      ];

      const truckLocations = [
        // North America
        { lat: 35.0, lng: -120.0, continent: 'North America' }, // Central California
        { lat: 42.0, lng: -85.0, continent: 'North America' },  // Western Michigan
        { lat: 41.0, lng: -75.0, continent: 'North America' },  // Eastern Pennsylvania

        // Europe
        { lat: 49.0, lng: 0.0, continent: 'Europe' },           // Northern France
        { lat: 54.0, lng: 10.0, continent: 'Europe' },          // Northern Germany
        { lat: 52.0, lng: -2.0, continent: 'Europe' },          // Central UK

        // Asia
        { lat: 40.0, lng: 115.0, continent: 'Asia' },           // Near Beijing
        { lat: 32.0, lng: 120.0, continent: 'Asia' },           // Near Shanghai
        { lat: 20.0, lng: 75.0, continent: 'Asia' },            // Central India

        // Africa
        { lat: 31.0, lng: 30.0, continent: 'Africa' },          // Near Cairo
        { lat: -27.0, lng: 30.0, continent: 'Africa' },         // Eastern South Africa
        { lat: 7.0, lng: 5.0, continent: 'Africa' },            // Southwestern Nigeria

        // South America
        { lat: -24.0, lng: -50.0, continent: 'South America' }, // Southern Brazil
        { lat: -35.0, lng: -60.0, continent: 'South America' }, // Central Argentina
        { lat: -23.0, lng: -44.0, continent: 'South America' }, // Near Rio de Janeiro

        // Australia
        { lat: -34.0, lng: 150.0, continent: 'Australia' },     // South of Sydney
        { lat: -38.0, lng: 145.0, continent: 'Australia' },     // Southeast of Melbourne
        { lat: -28.0, lng: 152.0, continent: 'Australia' },     // Inland from Brisbane
      ];

      const warehouseLocations = [
        // North America
        { lat: 39.0997, lng: -94.5786, continent: 'North America' }, // Kansas City
        { lat: 32.7767, lng: -96.7970, continent: 'North America' }, // Dallas

        // Europe
        { lat: 50.1109, lng: 8.6821, continent: 'Europe' },          // Frankfurt
        { lat: 52.3676, lng: 4.9041, continent: 'Europe' },          // Amsterdam

        // Asia
        { lat: 31.2304, lng: 121.4737, continent: 'Asia' },          // Shanghai
        { lat: 22.3193, lng: 114.1694, continent: 'Asia' },          // Hong Kong

        // Africa
        { lat: -26.2041, lng: 28.0473, continent: 'Africa' },        // Johannesburg
        { lat: -1.2921, lng: 36.8219, continent: 'Africa' },         // Nairobi

        // South America
        { lat: -23.5505, lng: -46.6333, continent: 'South America' }, // São Paulo
        { lat: -12.0464, lng: -77.0428, continent: 'South America' }, // Lima

        // Australia
        { lat: -33.8688, lng: 151.2093, continent: 'Australia' },    // Sydney
        { lat: -37.8136, lng: 144.9631, continent: 'Australia' },    // Melbourne
      ];

      const portLocations = [
        // North America
        { lat: 33.7360, lng: -118.2631, continent: 'North America' }, // Port of Los Angeles
        { lat: 40.6680, lng: -74.0419, continent: 'North America' },  // Port of New York and New Jersey

        // Europe
        { lat: 51.9475, lng: 4.1442, continent: 'Europe' },           // Port of Rotterdam
        { lat: 53.5460, lng: 9.9715, continent: 'Europe' },           // Port of Hamburg

        // Asia
        { lat: 31.2304, lng: 121.4737, continent: 'Asia' },           // Port of Shanghai
        { lat: 1.2644, lng: 103.8223, continent: 'Asia' },            // Port of Singapore

        // Africa
        { lat: -29.8700, lng: 31.0218, continent: 'Africa' },         // Port of Durban
        { lat: -4.0435, lng: 39.6682, continent: 'Africa' },          // Port of Mombasa

        // South America
        { lat: -23.9515, lng: -46.3272, continent: 'South America' }, // Port of Santos
        { lat: -34.6037, lng: -58.3816, continent: 'South America' }, // Port of Buenos Aires

        // Australia
        { lat: -37.8226, lng: 144.9083, continent: 'Australia' },     // Port of Melbourne
        { lat: -27.3842, lng: 153.1175, continent: 'Australia' },     // Port of Brisbane
      ];

      // Add Catena's location in Northern Virginia
      const catenaLocation = { lat: 39.0438, lng: -77.4874, text: 'Catena' };

      // Generate label data
      const labelsData = [
        ...vesselLocations.map(loc => ({ ...loc, text: 'Vessel', color: '#4285F4' })),
        ...truckLocations.map(loc => ({ ...loc, text: 'Truck', color: '#34A853' })),
        ...warehouseLocations.map(loc => ({ ...loc, text: 'Warehouse', color: '#FBBC05' })),
        ...portLocations.map(loc => ({ ...loc, text: 'Port', color: '#EA4335' })),
        catenaLocation
      ];

      // Generate arc data
      const arcsData = [
        // Truck arcs
        ...truckLocations.map(startLoc => {
          const possibleWarehouses = warehouseLocations.filter(wl => wl.continent === startLoc.continent);
          const endLoc = possibleWarehouses[Math.floor(Math.random() * possibleWarehouses.length)];
          return {
            startLat: startLoc.lat,
            startLng: startLoc.lng,
            endLat: endLoc.lat,
            endLng: endLoc.lng,
            color: '#34A853',
            arcAltitude: 0.1,
            arcSharpness: 0.7
          };
        }),
        // Vessel arcs
        ...vesselLocations.map(startLoc => {
          const possiblePorts = portLocations.filter(pl => pl.continent === startLoc.continent);
          const endLoc = possiblePorts[Math.floor(Math.random() * possiblePorts.length)];
          return {
            startLat: startLoc.lat,
            startLng: startLoc.lng,
            endLat: endLoc.lat,
            endLng: endLoc.lng,
            color: '#4285F4',
            arcAltitude: 0.1,
            arcSharpness: 0.7
          };
        }),

        // New arcs to Catena
        ...vesselLocations.map(loc => ({
          startLat: loc.lat,
          startLng: loc.lng,
          endLat: catenaLocation.lat,
          endLng: catenaLocation.lng,
          color: '#FFFFFF',
          arcAltitude: 0.3,
          arcStroke: 0.09,
          arcDashLength: 0.05,
          arcDashGap: 1.5,
        })),
        ...truckLocations.map(loc => ({
          startLat: loc.lat,
          startLng: loc.lng,
          endLat: catenaLocation.lat,
          endLng: catenaLocation.lng,
          color: '#FFFFFF',
          arcAltitude: 0.3,
          arcStroke: 0.09,
          arcDashLength: 0.05,
          arcDashGap: 1.5,
        })),
        ...warehouseLocations.map(loc => ({
          startLat: loc.lat,
          startLng: loc.lng,
          endLat: catenaLocation.lat,
          endLng: catenaLocation.lng,
          color: '#FFFFFF',
          arcAltitude: 0.3,
          arcStroke: 0.09,
          arcDashLength: 0.05,
          arcDashGap: 1.5,
        })),
        ...portLocations.map(loc => ({
          startLat: loc.lat,
          startLng: loc.lng,
          endLat: catenaLocation.lat,
          endLng: catenaLocation.lng,
          color: '#FFFFFF',
          arcAltitude: 0.3,
          arcStroke: 0.09,
          arcDashLength: 0.05,
          arcDashGap: 1.5,
        }))
      ];

      setGlobeData({ arcs: arcsData, labels: labelsData });
    }
  }, []);

  // Update the getLogoFilter function
  const getLogoFilter = () => {
    return theme.palette.mode === 'dark'
      ? 'brightness(0) invert(1)' // This will make the logos white in dark mode
      : 'brightness(0)'; // This will make the logos black in light mode
  };

  const fadeInUp = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 }
  };

  const scrollToContact = () => {
    const contactSection = document.getElementById('contact');
    if (contactSection) {
      contactSection.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const logoIntegrations = [
    'netsuite.png', 'mercurygate.png', 'sap.png', 'aljex.png', 'uberfreight.png',
    'tai.png', 'roserocket.svg', 'tmc.png', 'turvo.png',
    'manhattan.png', 'more.svg', 'salesforce.png', 'appian.png'
  ];

  const logoPartners = [
    'mesurio.png', 'xeneta.png', 'gather.svg', 'earndlt.png', 'overhaul.svg',
    'mojix.png', 'sitetrax.png', 'datasembly.svg', 'placerai.png', 'ceres.png',
    'keelvar.svg', 'optimaldynamics.png', 'levadata.png', 'breakthrough.png', 'eaigle.png',
    'arvist.svg', 'freightwaves.png', 'drumkit.png', 'form.svg', 'genlogs.png',
    'spire.png', 'loadpartner.png', 'autoscheduler.png', 'paxsafe.png', 'harmonya.png',
    'conduit.png', 'terminal49.png', 'vizion.svg', 'carriersource.png', 'highway.png'
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitStatus('submitting');

    emailjs.sendForm(
      process.env.REACT_APP_EMAILJS_SERVICE_ID,
      process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
      e.target,
      process.env.REACT_APP_EMAILJS_USER_ID
    )
      .then((result) => {
        setSubmitStatus('success');
        e.target.reset();
      })
      .catch((error) => {
        setSubmitStatus('error');
      })
      .finally(() => {
        setTimeout(() => setSubmitStatus(null), 5000);
      });
  };

  return (
    <div className="relative min-h-screen overflow-hidden" style={{ backgroundColor: theme.palette.background.default, color: theme.palette.text.primary }}>
      <main>
        <section className="container mx-auto px-4 sm:px-6 sm:py-15" style={{ color: theme.palette.text.primary }}>
          <motion.div
            className="flex flex-col-reverse md:flex-row items-center"
            initial="hidden"
            animate="visible"
            variants={{
              visible: { transition: { staggerChildren: 0.3 } }
            }}
          >
            <motion.div className="w-full md:w-1/2 md:pr-12 mt-8 md:mt-0 py-12" variants={fadeInUp}>
              <h1 className="text-3xl sm:text-4xl md:text-5xl font-extrabold mb-4 sm:mb-6 leading-tight text-center md:text-left">
                <span className="block sm:inline" style={{ color: theme.palette.text.primary }}>Universal Data</span>
                <span className="block sm:inline" style={{ color: theme.palette.text.primary }}>
                  <span className="hidden sm:inline"> </span>
                  Connector
                </span>
                <br className="hidden sm:block" />
                <span style={{ color: theme.palette.text.primary }}>for{" "}</span>
                <span className="whitespace-nowrap" style={{ color: theme.palette.primary.main }}>
                  <TextCycler
                    texts={[
                      "Freight Carriers",
                      "Wholesalers",
                      "Customs Brokers",
                      "Grocery Chains",
                      "Freight Forwarders",
                      "Big Box Retailers",
                      "Distributors",
                      "Specialty Stores",
                      "Supply Chains"
                    ]}
                    interval={4000}
                  />
                </span>
              </h1>
              <p className="text-lg sm:text-xl md:text-2xl mb-4 sm:mb-6 text-center md:text-left" style={{ color: theme.palette.text.secondary }}>
                We thrive at the intersection of data sources and destinations, serving as the crucial link that empowers organizations to overcome complex supply chain and logistics challenges.
              </p>
              <p className="text-lg sm:text-xl md:text-2xl mb-8 sm:mb-12 text-center md:text-left" style={{ color: theme.palette.text.secondary }}>
                Our advanced engine acts as a universal translator, seamlessly connecting disparate systems and converting raw data into actionable insights. By bridging the gap between information silos, we enable businesses to make informed decisions, optimize operations, and drive innovation across their entire ecosystem.
              </p>
              <div className="text-center md:text-left">
                <motion.button
                  className="bg-blue-600 text-white text-base sm:text-lg px-8 sm:px-10 py-3 sm:py-4 rounded-full hover:bg-blue-700 transition duration-300 shadow-lg hover:shadow-xl transform hover:-translate-y-1"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={scrollToContact}
                  style={{ backgroundColor: theme.palette.primary.main, color: theme.palette.primary.contrastText }}
                >
                  Request a Demo
                </motion.button>
              </div>
            </motion.div>
            <motion.div className="w-full md:w-1/2" variants={fadeInUp}>
              <div className="w-full h-[300px] sm:h-[400px] md:h-[600px] relative hidden md:block" style={{ marginTop: '-400px' , marginLeft: '-200px'}}>
                <Globe
                  ref={globeRef}
                  globeImageUrl={theme.palette.mode === 'dark' ? "/earth-dark.jpeg" : "/earth-blue-marble.jpg"}
                  arcsData={globeData.arcs}
                  arcColor={'color'}
                  arcDashLength={d => d.arcDashLength || 0.4}
                  arcDashGap={d => d.arcDashGap || 0.2}
                  arcDashAnimateTime={2500}
                  arcsTransitionDuration={2000}
                  arcStroke={d => d.arcStroke || 0.9}
                  arcAltitude={d => d.arcAltitude || 0.1}
                  labelsData={globeData.labels}
                  labelLat={d => d.lat}
                  labelLng={d => d.lng}
                  labelText={d => d.text}
                  labelSize={d => d.text === 'Catena' ? 1.5 : 1.1}
                  labelColor={d => d.color || '#FFFFFF'}
                  labelDotRadius={d => d.text === 'Catena' ? 0.6 : 0.4}
                  labelAltitude={0.01}
                  backgroundColor="rgba(0,0,0,0)"
                  atmosphereColor="#4285F4"
                  atmosphereAltitude={0.25}
                  width={1300}
                  height={1300}
                  enableZoom={false}
                  labelFont={{
                    family: "'Inter', sans-serif",
                    weight: 'bold',
                    size: 14,
                    color: '#ffffff',
                    outlineWidth: 1,
                    outlineColor: '#000000'
                  }}
                />
              </div>
            </motion.div>
          </motion.div>
        </section>

        <motion.section
          className="py-10 sm:py-14 mt-20 sm:mt-36 relative z-10"
          style={{ backgroundColor: theme.palette.background.paper, color: theme.palette.text.primary }}
        >
          <div className="container mx-auto px-4 sm:px-6">
            <motion.div
              className="flex flex-col sm:flex-row items-center justify-center"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.2, duration: 0.6 }}
            >
              <h2 className="text-xl sm:text-2xl font-bold mb-4 sm:mb-0 sm:mr-8 text-center sm:text-left">
                Founded by industry veterans from:
              </h2>
              <div className="flex flex-wrap items-center justify-center">
                {['founded/walmart.svg', 'founded/bloomberg.svg', 'founded/interos.svg', 'founded/vizion.svg'].map((company, index) => (
                  <motion.img
                    key={company}
                    src={`/${company}`}
                    alt={`${company.split('/')[1].split('.')[0]} logo`}
                    className="h-5 sm:h-6 object-contain mx-2 sm:mx-4 my-2"
                    style={{ filter: getLogoFilter() }}
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true }}
                    transition={{ delay: 0.3 + index * 0.1, duration: 0.5 }}
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                  />
                ))}
              </div>
            </motion.div>
          </div>
        </motion.section>

        <section id="solutions" className="py-16 sm:py-24" style={{ backgroundColor: theme.palette.background.default, color: theme.palette.text.primary }}>
          <div className="container mx-auto px-4 sm:px-6">
            <FadeInSection>
              <h2 className="text-3xl sm:text-4xl font-bold text-center mb-12 sm:mb-16" style={{ color: theme.palette.text.primary }}>
                Our Core Capabilities
              </h2>
            </FadeInSection>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 sm:gap-12">
              {[
                { icon: FaDatabase, title: "Data Sourcing", description: "Source 1st and 2nd party data from leading supply chain applications and 3rd parties." },
                { icon: FaLink, title: "Rapid Connections", description: "Deliver new connections to ERPs, WMS, OMS, TMS, and many more in minutes, not weeks." },
                { icon: FaExchangeAlt, title: "Data Transformation", description: "Validate, enrich, and standardize data from various endpoints and sources." },
                { icon: FaChartLine, title: "Analytics Routing", description: "Route transformed data to the right destination for analytics and system processes." },
              ].map((feature, index) => (
                <FadeInSection key={index}>
                  <div className="rounded-lg p-8 text-center shadow-lg hover:shadow-xl transition duration-300 transform hover:-translate-y-2 h-full flex flex-col justify-between" style={{ backgroundColor: theme.palette.background.paper, color: theme.palette.text.primary }}>
                    <div>
                      <feature.icon className="text-5xl mx-auto mb-6" style={{ color: theme.palette.primary.main }} />
                      <h3 className="text-1xl font-semibold mb-4 whitespace-nowrap overflow-hidden text-ellipsis" style={{ color: theme.palette.text.primary }}>{feature.title}</h3>
                    </div>
                    <p style={{ color: theme.palette.text.secondary }}>{feature.description}</p>
                  </div>
                </FadeInSection>
              ))}
            </div>
          </div>
        </section>

        <section className="py-8 sm:py-12 relative overflow-hidden" style={{ backgroundColor: theme.palette.background.paper, color: theme.palette.text.primary }}>
          <div className="container mx-auto px-4 sm:px-6">
            <FadeInSection>
              <h2 className="text-3xl sm:text-4xl font-bold text-center mb-8">
                Rapidly Integrate with Your Existing Systems
              </h2>
            </FadeInSection>
            <div className="relative">
              <div className="absolute left-0 top-0 bottom-0 w-1/6 z-10" style={{
                background: `linear-gradient(to right, ${theme.palette.background.paper}, transparent)`
              }}></div>
              <div className="absolute right-0 top-0 bottom-0 w-1/6 z-10" style={{
                background: `linear-gradient(to left, ${theme.palette.background.paper}, transparent)`
              }}></div>
              <div className="logo-carousel">
                <div className="logo-track">
                  {Array(10).fill(logoIntegrations).flat().map((logo, index) => (
                    <div key={index} className="logo-slide">
                      <img
                        src={`/integrations/${logo}`}
                        alt={`${logo.split('.')[0]} logo`}
                        className="h-10 sm:h-14 object-contain mx-auto"
                        style={{
                          filter: getLogoFilter(),
                          transition: 'filter 0.3s'
                        }}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="about" className="py-16" style={{ backgroundColor: theme.palette.background.default, color: theme.palette.text.primary }}>
          <div className="container mx-auto px-4 sm:px-6">
            <FadeInSection>
              <h2 className="text-3xl sm:text-4xl font-bold text-center mb-12 sm:mb-16">Supply Chain Functional Areas We Serve</h2>
            </FadeInSection>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 sm:gap-12">
              {[
                { icon: FaIndustry, title: "Supplier Resilience", description: "Tier supplier management, competitive RFPs, and cost optimization." },
                { icon: FaShippingFast, title: "Asset Monitoring", description: "End to end visibility, shipping milestones, and threat assessment." },
                { icon: FaWarehouse, title: "External Manufacturing", description: "Raw materials, co-manufacturing, co-packaging, and logistics." },
                { icon: FaChartBar, title: "Demand Planning", description: "Shelf intelligence, PoS management, and inventory replenishment." },
              ].map((area, index) => (
                <FadeInSection key={index}>
                  <div className="rounded-lg p-8 text-center shadow-lg hover:shadow-xl transition duration-300 transform hover:-translate-y-2 h-full flex flex-col justify-between" style={{ backgroundColor: theme.palette.background.paper, color: theme.palette.text.primary }}>
                    <div>
                      <area.icon className="text-5xl mx-auto mb-6" style={{ color: theme.palette.primary.main }} />
                      <h3 className="text-1xl font-semibold mb-4 whitespace-nowrap overflow-hidden text-ellipsis" style={{ color: theme.palette.text.primary }}>{area.title}</h3>
                    </div>
                    <p style={{ color: theme.palette.text.secondary }}>{area.description}</p>
                  </div>
                </FadeInSection>
              ))}
            </div>
          </div>
        </section>

        <section className="py-8 sm:py-12 relative overflow-hidden" style={{ backgroundColor: theme.palette.background.paper, color: theme.palette.text.primary }}>
          <div className="container mx-auto px-4 sm:px-6">
            <FadeInSection>
              <h2 className="text-3xl sm:text-4xl font-bold text-center mb-8">
                Integrate with Our Data Partners
              </h2>
            </FadeInSection>
            <div className="relative">
              <div className="absolute left-0 top-0 bottom-0 w-1/6 z-10" style={{
                background: `linear-gradient(to right, ${theme.palette.background.paper}, transparent)`
              }}></div>
              <div className="absolute right-0 top-0 bottom-0 w-1/6 z-10" style={{
                background: `linear-gradient(to left, ${theme.palette.background.paper}, transparent)`
              }}></div>
              <div className="logo-carousel">
                <div className="logo-track">
                  {Array(10).fill(logoPartners).flat().map((logo, index) => (
                    <div key={index} className="logo-slide">
                      <img
                        src={`/partners/${logo}`}
                        alt={`${logo.split('.')[0]} logo`}
                        className="h-10 sm:h-14 object-contain mx-auto"
                        style={{
                          filter: getLogoFilter(),
                          transition: 'filter 0.3s'
                        }}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>

        <motion.section
          className="py-10 sm:py-14 relative z-10"
          style={{ backgroundColor: theme.palette.background.default, color: theme.palette.text.primary }}
        >
          <div className="container mx-auto px-4 sm:px-6">
            <motion.div
              className="flex flex-row items-center justify-center"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.2, duration: 0.6 }}
            >
              <h2 className="text-xl sm:text-2xl font-bold mr-8 text-left">
                Backed by leading investors:
              </h2>
              <div className="flex items-center justify-center">
                {['investors/shaper.png', 'investors/teamworthy.png'].map((company, index) => (
                  <motion.img
                    key={company}
                    src={`/${company}`}
                    alt={`${company.split('/')[1].split('.')[0]} logo`}
                    className="h-16 sm:h-24 object-contain mx-2 sm:mx-4"
                    style={{ filter: getLogoFilter() }}
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true }}
                    transition={{ delay: 0.3 + index * 0.1, duration: 0.5 }}
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                  />
                ))}
              </div>
            </motion.div>
          </div>
        </motion.section>

        <section id="contact" className="container mx-auto px-4 sm:px-6 py-16 sm:py-24">
          <div className={`rounded-3xl shadow-2xl overflow-hidden ${theme.palette.mode === 'dark' ? 'bg-gradient-to-r from-blue-900 to-blue-700' : 'bg-gradient-to-r from-blue-600 to-blue-800'}`}>
            <div className="md:flex items-center">
              <div className="md:w-1/2 p-8 sm:p-12">
                <h2 className="text-3xl sm:text-4xl font-bold text-white mb-4 sm:mb-6">Transform Your Supply Chain with Data-Driven Insights</h2>
                <p className="text-lg sm:text-xl text-blue-100 mb-6 sm:mb-8">
                  Discover how Catena can revolutionize your supply chain management. Our expert team is ready to guide you through a personalized demo.
                </p>
                <button
                  onClick={scrollToContact}
                  className={`px-8 py-3 rounded-full font-semibold transition duration-300 shadow-md hover:shadow-lg transform hover:-translate-y-0.5 ${
                    theme.palette.mode === 'dark'
                      ? 'bg-blue-200 text-blue-900 hover:bg-blue-300'
                      : 'bg-white text-blue-600 hover:bg-blue-50'
                  }`}
                >
                  Request a Demo
                </button>
              </div>
              <div className={`md:w-1/2 p-8 sm:p-12 rounded-t-3xl md:rounded-l-3xl md:rounded-tr-none ${
                theme.palette.mode === 'dark' ? 'bg-gray-800' : 'bg-white'
              }`}>
                <form className="space-y-4 sm:space-y-6" onSubmit={handleSubmit}>
                  <input
                    type="text"
                    name="full_name"
                    placeholder="Full Name"
                    className={`w-full p-4 rounded-md border transition duration-300 ${
                      theme.palette.mode === 'dark'
                        ? 'bg-gray-700 border-gray-600 text-white placeholder-gray-400 focus:border-blue-500 focus:ring focus:ring-blue-500'
                        : 'border-gray-300 focus:border-blue-500 focus:ring focus:ring-blue-200'
                    }`}
                    required
                  />
                  <input
                    type="email"
                    name="work_email"
                    placeholder="Work Email"
                    className={`w-full p-4 rounded-md border transition duration-300 ${
                      theme.palette.mode === 'dark'
                        ? 'bg-gray-700 border-gray-600 text-white placeholder-gray-400 focus:border-blue-500 focus:ring focus:ring-blue-500'
                        : 'border-gray-300 focus:border-blue-500 focus:ring focus:ring-blue-200'
                    }`}
                    required
                  />
                  <input
                    type="tel"
                    name="phone_number"
                    placeholder="Phone Number"
                    className={`w-full p-4 rounded-md border transition duration-300 ${
                      theme.palette.mode === 'dark'
                        ? 'bg-gray-700 border-gray-600 text-white placeholder-gray-400 focus:border-blue-500 focus:ring focus:ring-blue-500'
                        : 'border-gray-300 focus:border-blue-500 focus:ring focus:ring-blue-200'
                    }`}
                    required
                  />
                  <input
                    type="text"
                    name="company_name"
                    placeholder="Company Name"
                    className={`w-full p-4 rounded-md border transition duration-300 ${
                      theme.palette.mode === 'dark'
                        ? 'bg-gray-700 border-gray-600 text-white placeholder-gray-400 focus:border-blue-500 focus:ring focus:ring-blue-500'
                        : 'border-gray-300 focus:border-blue-500 focus:ring focus:ring-blue-200'
                    }`}
                    required
                  />
                  <textarea
                    name="content"
                    placeholder="Tell us about your supply chain data challenges"
                    className={`w-full p-4 rounded-md border transition duration-300 h-32 ${
                      theme.palette.mode === 'dark'
                        ? 'bg-gray-700 border-gray-600 text-white placeholder-gray-400 focus:border-blue-500 focus:ring focus:ring-blue-500'
                        : 'border-gray-300 focus:border-blue-500 focus:ring focus:ring-blue-200'
                    }`}
                    required
                  ></textarea>
                  <div className="flex items-center space-x-4">
                    <button
                      type="submit"
                      className={`flex-shrink-0 py-3 px-6 sm:py-4 sm:px-8 rounded-full font-semibold transition duration-300 shadow-md hover:shadow-lg transform hover:-translate-y-0.5 ${
                        theme.palette.mode === 'dark'
                          ? 'bg-blue-600 text-white hover:bg-blue-700'
                          : 'bg-blue-600 text-white hover:bg-blue-700'
                      } ${submitStatus === 'submitting' ? 'opacity-50 cursor-not-allowed' : ''}`}
                      disabled={submitStatus === 'submitting'}
                    >
                      {submitStatus === 'submitting' ? 'Submitting...' : 'Submit'}
                    </button>

                    <AnimatePresence>
                      {(submitStatus === 'success' || submitStatus === 'error') && (
                        <motion.div
                          key={submitStatus}
                          initial={{ opacity: 0, x: -20 }}
                          animate={{ opacity: 1, x: 0 }}
                          exit={{ opacity: 0, x: -20 }}
                          transition={{ duration: 0.5 }}
                          className={`flex-grow p-2 rounded-md text-white text-sm ${
                            submitStatus === 'success' ? 'bg-green-500' : 'bg-red-500'
                          }`}
                        >
                          {submitStatus === 'success'
                            ? (
                              <div className="flex items-center">
                                <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                                </svg>
                                <span>Thank you! We'll be in touch soon.</span>
                              </div>
                            )
                            : (
                              <div className="flex items-center">
                                <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                </svg>
                                <span>Failed to send. Please try again.</span>
                              </div>
                            )}
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default LandingPage;
